<template>
    <div class="card">
        <slot></slot>
    </div>
</template>


<style scoped>

.card{
   width: fit-content;
   height: fit-content;
   border: 0px solid;
   border-radius:10px;
   padding: 30px;
   box-shadow: 0px 0px 8px 4px  rgba(0, 0, 0, 0.25);
}

</style>

