<template>
  <Header/>
  <router-view/>
</template>


<script>
import Header from './components/TheHeader'
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  components:{
    Header
  },
  data(){
    return{

    }
  },
  mounted(){

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
    this.$store.state.userid=uid
    console.log(uid)
    this.$store.state.loggedin=true
    // ...
  } else {
    // User is signed out
    // ...
    this.$store.state.loggedin=false
  }
});
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
