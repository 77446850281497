<template>

    <div v-if="loading">
        <img class="load" src="../assets/Spin.png" alt="">
    </div>
    <ul v-else>
        <Products v-for="product in $store.state.products"
        :key="product.title"
        :id="product.id"
        :title="product.title"
        :price="product.price"
        :image="product.image"
        />
    </ul>
</template>


<script>
import Products from '../components/TheProducts.vue'
import { collection, getDocs } from "firebase/firestore";
import db from '../Firebase/firebase'

export default {
    components:{
        Products
    },
    data(){
        return{
            loading:'true'
        }
    },
    async mounted(){
        // this.loading=true
        const querySnapshot = await getDocs(collection(db, "products"));
        const pros=[]
        querySnapshot.forEach((doc) => {
            const pro={
                id:doc.id,
                title:doc.data().title ,
                price:doc.data().price ,
                description:doc.data().description ,
                image:doc.data().image ,
                rating: doc.data().rating,
                category: doc.data().category,
            }
            pros.push(pro)

        })
        this.$store.state.products=pros
        console.log(this.$store.state.userid)
        this.loading=false
    }

}
</script>




<style scoped>
ul{
    margin-top:50px;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    row-gap: 45px;
}

.load{
    width: 100px;
    margin-top:30vh ;
}

</style>