<template>
  <div v-if="loading">
    <img class="load" src="../assets/Spin.png" alt="">
  </div>
  <div v-else class="product">  
    <div class="img">
        <img :src="prodcutdtails.image" >
        <h3>{{prodcutdtails.title}}</h3>
        <h3>{{prodcutdtails.price}}/-</h3>
    </div>
    <div class="descr">
        <h3>{{prodcutdtails.description}}</h3>
        <button @click="addcart">Add to cart</button><br>
    </div>
  </div>
</template>



<script>
    import { doc, setDoc } from "firebase/firestore"; 
import { collection, getDocs } from "firebase/firestore";
import db from '../Firebase/firebase'

export default {
    
    props:['id'],
    data(){
        return{
            prodcutdtails:'',
            loading:'true'
        }
    },
    methods:{
        addcart(){
            this.$store.state.cart.push(this.prodcutdtails)
            console.log(this.prodcutdtails)


    setDoc(doc(db, "cart",this.$store.state.userid+'-'+this.prodcutdtails.id), {
        pid: this.prodcutdtails.id,
        image: this.prodcutdtails.image,
        description: this.prodcutdtails.description,
        title: this.prodcutdtails.title,
        price: this.prodcutdtails.price,
});
        },
        async load(){
            // this.loading=true
            const querySnapshot = await getDocs(collection(db, "products"));
            const pros=[]
            querySnapshot.forEach((doc) => {
                const pro={
                    id:doc.id,
                    title:doc.data().title ,
                    price:doc.data().price ,
                    description:doc.data().description ,
                    image:doc.data().image ,
                    rating: doc.data().rating,
                    category: doc.data().category,
                }
                pros.push(pro)

            })
            this.$store.state.products=pros
            this.loading=false
        }
        
    },
    async mounted(){
        await this.load()
        this.prodcutdtails=this.$store.state.products.find(pro=>pro.id==this.$route.params.id)
    }
}
</script>






<style scoped>
h3{
    margin-top: 30px;
}

 img{
    margin-top: 40px;
    max-width: 500px;
 }

 .img{
    align-self: center;
 }

.product{
    display: grid;
    grid-template-columns:1.5fr 1fr;
    /* margin: auto 0px; */
    height: 90vh;
}

.load{
    width: 100px;
    margin-top:30vh ;
}

.descr{
    margin: auto 0px;
    margin-right: 100px;
}

button{
    font-size: 30px;
    width: 200px;
    color: rgba(0, 0, 0, 0.599);
    margin-top: 50px;
    border: none;
    padding: 10px;
    background: wheat;
    border-radius: 8px;
}

button:hover{
    cursor: pointer;
}

</style>