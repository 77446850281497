import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/ProductsView.vue'
import Orders from '../views/OrdersView.vue'
import Profile from '../views/ProfileView.vue'
import Productdetails from '../views/ProductDetails.vue'
import Cart from '../views/CartView.vue'
import Signup from '../views/SignUpView.vue'
import Login from '../views/LoginView.vue'


const routes = [
  {
    path: '/',
    redirect:'/productds'
  },
  {
    path: '/productds',
    name: 'productds',
    component: HomeView,
  },
  {
    path: '/productds/:id',
    name: 'Productdetails',
    component: Productdetails,
    props:true
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});




export default router
