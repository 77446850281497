<template>
   
    <div class="card">
    <router-link :to='details'>
            <img :src="image" alt="">
    </router-link>
    <div class="details">
            <div class="details">{{title}}</div>
            <div class="details">{{price}}/-</div>
    </div>
    </div>

     

</template>

<script>

export default {
props:['id','image','title','price'],
    computed:{
        details(){
            return '/productds/'+this.id
        }
    }
}
</script>



<style scoped>

img{
    max-height: 300px;
    max-width: 200px;
    overflow: hidden;
}



.card{
    width: 300px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid rgba(0, 0, 0, 0.2);
    
}

.details{
    margin-top: 10px;
}

</style>