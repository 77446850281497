<template>
  <div class="background">
    <base-card>
      <form @submit.prevent>
        <div class="already">
          <span class="already">Don't have an account?</span>
          <router-link to="/signup">
          <base-button>SignUp</base-button>   
          </router-link>
        </div>
        <div class="label">
          <label for="email">Email</label>
          <input id="email" type="email" required v-model="email">
        </div>
        <div class="label">
          <label for="password">Password</label>
          <input type="password" required v-model="password">
        </div>
        <base-button @click="login">
            Login
        </base-button>
      </form>
    </base-card>
  </div>
</template>


<script>
import BaseCard from '../components/UI/BaseCard.vue'
import BaseButton from '../components/UI/BaseButton.vue'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
    components:{
        BaseCard,
        BaseButton
    },
    data(){
      return{
        match:''
      }
    },
    methods:{
      hello(){

      },
      login(){
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log(user)
            this.$router.push('/')
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(error)
            console.log(errorCode)
            console.log(errorMessage)
          });
      }
    }

}
</script>


<style scoped>

.already{
  margin-bottom: 30px;
  font-size: 20px;
}

.already span{
  margin-right:20px ;
}



.background{
    background:url('../assets/login.jpg');
    height: 90vh;
    width: 100vw;
    position: absolute;
    background-repeat: no-repeat;
    background-size: auto 80vh;
    background-position:37vw;
    overflow:hidden;
    z-index: -1;
}

input{
    height: 40px;
    border-radius: 8px;
    border: 1.5px solid rgba(0, 0, 0, 0.575);
    width: 400px;
    font-size: 20px;
    text-indent: 10px;
}
.fullname input{
    width: 250px;
}

#check{
    height: 15px;
    width: 15px;
     margin-right:10px;
}

.box label{
    margin-top:-3px ;
}

label{
    font-size: 20px;
    margin-bottom:5px ;
}

.fullname{
    display: flex;
    column-gap: 50px;
}

.label{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: flex-start;
}

form{
  display: flex;
  flex-direction: column;
}

.card{
    margin:120px 0px 0px 90px;
    margin:220px 0px 0px 150px ;
    background: rgba(255, 255, 255,.5);
}

.box{
    display: flex;
    margin-bottom:20px ;
}

button{
    width: fit-content;
}


#app{
    background: red;
}
</style>